<template>
  <b10-base>
    <v-stepper
      vertical
      non-linear
      :value="currentStep"
      class="elevation-0"
    >
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.datosGenerales.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.datosGenerales.title }}
          <small>{{ steps.datosGenerales.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.datosGenerales.id"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="descripcion"
            :rules="formRules.descripcion"
          >
            <v-text-field
              v-model="form.descripcion"
              clearable
              flat
              label="Descripción"
              :error-messages="errors"
            />
          </ValidationProvider>
          <b10-textarea
            v-model="form.observaciones"
            label="Observaciones"
          />
          <ValidationProvider
            v-slot="{ errors }"
            name="serie_operacioncrm"
            :rules="formRules.idserie_operacioncrm"
          >
            <b10-autocomplete
              v-model="form.idserie_operacioncrm"
              :items="seriesOperacionCRM"
              item-text="descripcorta"
              label="Serie"
              clearable
              :error-messages="errors"
              return-object
              no-filter
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="idtoperacioncrm"
            :rules="formRules.idtoperacioncrm"
          >
            <b10-autocomplete
              v-model="form.idtoperacioncrm"
              :items="tiposOperacionCRM"
              item-text="descripcion"
              label="Tipo"
              clearable
              :error-messages="errors"
              return-object
              no-filter
              @change="toperacionCRMSelected"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="est_operacioncrm"
            :rules="formRules.idest_operacioncrm"
          >
            <b10-autocomplete
              v-model="form.idest_operacioncrm"
              :items="estadosPorTipoDeOperacionCRM"
              item-text="descripcion"
              item-value="idest_operacioncrm"
              label="Estado"
              clearable
              :error-messages="errors"
              no-filter
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            :rules="formRules.idprioridad"
          >
            <b10-autocomplete
              v-model="form.idprioridad"
              :items="prioridades"
              item-text="descripcion"
              item-value="idprioridad"
              label="Prioridad"
              clearable
              :error-messages="errors"
              no-filter
            />
          </ValidationProvider>
          <b10-autocomplete
            v-model="form.iddelegacion"
            :items="delegaciones"
            item-text="descripcion"
            item-value="iddelegacion"
            label="Delegación"
            clearable
            no-filter
          />
        </v-stepper-content>
      </ValidationObserver>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <!-- Planificación -->
        <v-stepper-step
          :step="steps.planificacion.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.planificacion.title }}
          <small>{{ steps.planificacion.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.planificacion .id"
        >
          <b10-autocomplete
            v-model="form.iddepartamento"
            :items="departamentos"
            item-text="descripcion"
            item-value="iddepartamento"
            label="Departamento"
            clearable
          />
          <v-checkbox
            v-model="form.filtrar_por_departamento"
            label="Filtrar responsable por departamento"
            @change="filtrarPorDepartamentoChange"
          />
          <b10-autocomplete
            v-model="form.responsable"
            :items="empleados"
            placeholder="Escribe para buscar..."
            :append-icon="null"
            hide-no-data
            clearable
            item-text="nombre_y_apellido"
            return-object
            label="Responsable"
            :search-input.sync="empleadoSearch"
            no-filter
          />
          <ValidationProvider
            v-slot="{ errors }"
            :rules="formRules.fproximarevision"
          >
            <b10-date-time-picker
              v-model="form.fproximarevision"
              title="Fecha de próxima revisión"
              :error-messages="errors"
            />
          </ValidationProvider>
          <b10-date-time-picker
            v-model="form.fprevistafin"
            title="Fecha prevista de fin"
          />
        </v-stepper-content>
      </ValidationObserver>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <!-- cita -->
        <v-stepper-step
          v-if="isInsert"
          :step="steps.cita.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.cita.title }}
          <small>{{ steps.cita.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.cita.id"
        >
          <ValidationProvider
            name="cita"
          >
            <v-checkbox
              v-model="form.cita"
              label="Introducir cita"
              @change="citaSelected"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="cita_fecha"
            :rules="formRules.cita_fecha"
          >
            <b10-date-picker
              ref="citaFecha"
              v-model="form.cita_fecha"
              title="Fecha"
              :disabled="!form.cita"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="cita_inicio"
            :rules="formRules.cita_inicio"
          >
            <b10-time-picker
              ref="citaFecha"
              v-model="form.cita_inicio"
              title="Inicio"
              :disabled="!form.cita"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="cita_fin"
            :rules="formRules.cita_fin"
          >
            <b10-time-picker
              ref="citaFecha"
              v-model="form.cita_fin"
              title="Fin"
              :disabled="!form.cita"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="cita_titulo"
            :rules="formRules.cita_titulo"
          >
            <v-text-field
              v-model="form.cita_titulo"
              clearable
              flat
              label="Descripción"
              :disabled="!form.cita"
              :error-messages="errors"
            />
          </ValidationProvider>
          <b10-textarea
            v-model="form.cita_cita"
            :disabled="!form.cita"
            label="Observaciones"
          />
        </v-stepper-content>
      </ValidationObserver>
    </v-stepper>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './OperacioncrmFormData'
import _ from '@/utils/lodash'
import { PARAMETRO } from '@/utils/consts'
import  { extend } from 'vee-validate'

export default {
  mixins: [formMixin],
  props: {
    tabla: {
      type: Object,
      default: () => {},
    },
    idempleado: {
      type: Number,
      default: null
    },
    iddelegacion: {
      type: Number,
      default: null
    },
  },
  data () {
    return {
      form: {
        descripcion: null,
        idserie_operacioncrm: null,
        idtoperacioncrm: null,
        idest_operacioncrm: null,
        iddelegacion: null,
        iddepartamento: null,
        responsable: null,
        fproximarevision: null,
        fprevistafin: null,
        filtrar_por_departamento: true,
        observaciones: null,
        idprioridad: null,
        // cita
        cita: false,
        cita_fecha: null,
        cita_inicio: null,
        cita_fin: null,
        cita_titulo: null,
        cita_cita: null,
      },
      formRules: {
        descripcion: { required: true },
        idserie_operacioncrm: { required: true },
        idtoperacioncrm: { required: true },
        idest_operacioncrm: { required: true },
        descripcion: { required: true },
        fproximarevision: { required: true },
        idprioridad: { required: true },
        cita_fecha: { required_if: { target: 'cita', value: true } },
        cita_inicio: { required_if: { target: 'cita', value: true } },
        cita_fin: { fechaFinValida: true },
        cita_titulo: { required_if: { target: 'cita', value: true } },
      },
      steps: {
        datosGenerales: {
          id: 1,
          title: 'Datos generales',
          subtitle: 'Datos generales y configuración de la operación',
        },
        planificacion: {
          id: 2,
          title: 'Planificación',
          subtitle: 'Asignación de fechas y responsable',
        },
        cita: {
          id: 3,
          title: 'Nueva cita',
          subtitle: 'Crear una cita relacionada con la operación',
        },
      },
      currentStep: 1,
      seriesOperacionCRM: [],
      tiposOperacionCRM: [],
      estadosOperacionCRM: [],
      departamentos: [],
      estadosPorTipoDeOperacionCRM: [],
      empleados: [],
      empleadoSearch: '',
      prioridades: [],
      delegaciones: [],
      tiposContacto: [],
      canelesComunicacion: [],
    }
  },
  watch: {
    empleadoSearch (value) {
      value && value.length >= 3 && value !== this.form.responsable && this.empleadoDoSearch(value)
    },
  },
  async created () {
    const parametrosNecesarios = [
      PARAMETRO.parametros.IDPRIORIDAD_DEFECTO_CRM,
      PARAMETRO.parametros.SERIE_OPERACION_CRM_DEFECTO,
    ]
    const resp = await Data.selectLookups(this, parametrosNecesarios)
    this.seriesOperacionCRM = resp.data.selectSerieOperacionCRM.result.dataset
    this.tiposOperacionCRM = resp.data.selectToperacionCRM.result.dataset
    this.estadosOperacionCRM = resp.data.selectEstadoOperacionCRM.result.dataset
    this.departamentos = resp.data.selectDepartamento.result.dataset
    this.prioridades = resp.data.selectvOperacioncrmPrioridad.result.dataset
    this.delegaciones = resp.data.selectDelegacion.result.dataset
    this.tiposContacto = resp.data.selectTcontacto.result.dataset
    this.canelesComunicacion = resp.data.selectCanalComunicacion.result.dataset
    if (this.isInsert) {
      const parametroPrioridaDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.IDPRIORIDAD_DEFECTO_CRM })
      if (parametroPrioridaDefecto) {
        const existePrioridadDefecto = _.find(this.prioridades, { idprioridad: parseInt(parametroPrioridaDefecto.valor) })
        if (existePrioridadDefecto) {
          this.$set(this.form, 'idprioridad', parseInt(parametroPrioridaDefecto.valor))
        }
      }
      const parametroSeriePorDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.SERIE_OPERACION_CRM_DEFECTO })
      if (parametroSeriePorDefecto) {
        const existeSeriePorDefecto = _.find(this.seriesOperacionCRM, { idserie_operacioncrm: parseInt(parametroSeriePorDefecto.valor) })
        if (existeSeriePorDefecto) {
          this.form.idserie_operacioncrm = _.find(this.seriesOperacionCRM, { 'idserie_operacioncrm': parseInt(parametroSeriePorDefecto.valor) })
        }
      }
      this.$set(this.form, 'idinformador', this.idempleado)
      this.$set(this.form, 'iddelegacion', this.iddelegacion)
    }
    extend('fechaFinValida', {
      message: 'La fecha de fin debe ser posterior a la de inicio.',
      validate: async (value) => {
        if (value && value < this.form.cita_inicio) {
          return false
        } else {
          return true
        }
      }
    })
    await this.loadForm()
  },
  methods: {
    async asignarEmpleado (form, idempleado) {
      this.empleados = await this.$online.empleado.row(idempleado)
      this.$set(form, 'responsable', _.find(this.empleados, { 'idempleado': idempleado}))
    },
    async empleadoDoSearch (value) {
      const iddepartamento = this.form.filtrar_por_departamento ? this.form.iddepartamento : null
      this.empleados = await Data.selectEmpleadoSearch(this, value, iddepartamento)
    },
    async toperacionCRMSelected (tipoOpercionCRM){
      if (tipoOpercionCRM) {
        this.estadosPorTipoDeOperacionCRM = _.filter(this.estadosOperacionCRM, { idtoperacioncrm: tipoOpercionCRM.idtoperacioncrm})
        const idEstadoInicial = _.find(this.estadosPorTipoDeOperacionCRM, {estado_inicial: true})
        this.form.idest_operacioncrm = _.find(this.estadosOperacionCRM, { 'idest_operacioncrm': idEstadoInicial.idest_operacioncrm })
        this.$set(this.form, 'idest_operacioncrm', idEstadoInicial.idest_operacioncrm)
        if (this.isInsert) {
          this.$set(this.form, 'iddepartamento', tipoOpercionCRM.iddepartamento)
          await this.asignarEmpleado(this.form, tipoOpercionCRM.idresponsable)
        }
      } else {
        this.estadosPorTipoDeOperacionCRM = []
        this.$set(this.form, 'idest_operacioncrm', null)
        this.$set(this.form, 'iddepartamento', null)
      }
    },
    async filtrarPorDepartamentoChange () {
      this.empleados = []
    },
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      const selectLookupsFormData = await Data.selectLookupsFormData(
        this,
        formData.idtoperacioncrm,
        formData.idresponsable
      )
      formData.idtoperacioncrm = _.find(this.tiposOperacionCRM, { 'idtoperacioncrm': formData.idtoperacioncrm })
      formData.idserie_operacioncrm = _.find(this.seriesOperacionCRM, { 'idserie_operacioncrm': formData.idserie_operacioncrm })
      // forzar evento
      await this.toperacionCRMSelected(selectLookupsFormData.data.selectToperacionCRM.result.dataset[0])
      await this.asignarEmpleado(formData, formData.idresponsable)
      this.form = formData
    },
    citaSelected (value) {
      if (!value) {
        this.$set(this.form, 'cita_fecha', null)
        this.$set(this.form, 'cita_inicio', null)
        this.$set(this.form, 'cita_fin', null)
        this.$set(this.form, 'cita_titulo', null)
      }
    },
  },
}
</script>
