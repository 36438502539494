import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectLookups (Vue, idsParametros) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectSerieOperacionCRM', method: 'serieOperacioncrm.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectToperacionCRM', method: 'toperacioncrm.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectEstadoOperacionCRM', method: 'estOperacioncrm.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectDepartamento', method: 'departamento.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectDelegacion', method: 'delegacion.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTcontacto', method: 'tcontacto.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectCanalComunicacion', method: 'canalComunicacion.select', params: { filter: apiFilterEstadoActivo } })
    const apiFilterParametros = new APIFilter()
    apiFilterParametros.addIn('idparametro', idsParametros)
    apiCalls.push({ name: 'selectParametro', method: 'parametro.select', params: { filter: apiFilterParametros } })
    // vistas
    apiCalls.push({ name: 'selectvOperacioncrmPrioridad', method: 'vOperacioncrmPrioridad.select' })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectEmpleadoSearch (Vue, search, iddepartamento) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('estado', 1)
    apiFilter.addNestedFilter(Vue.$online.empleado.searchFilter(search))
    if (iddepartamento) {
      apiFilter.addExact('iddepartamento', iddepartamento)
    }
    const resp = await Vue.$api.call(
      'empleado.select',
      {
        filter: apiFilter,
        page: 0,
        page_size: Vue.$api.ITEMS_PER_PAGE_AUTOCOMPLETE,
      },
      {
        disableUI: false,
      },
    )
    return resp.data.result.dataset
  },
  async selectLookupsFormData (Vue, idtoperacioncrm, idempleado) {
    let apiCalls = []
    const apiFilterTipoOperacionCRM = new APIFilter()
    apiFilterTipoOperacionCRM
      .addGT('estado', 0)
      .addExact('idtoperacioncrm', idtoperacioncrm)
    apiCalls.push({ name: 'selectToperacionCRM', method: 'toperacioncrm.select', params: { filter: apiFilterTipoOperacionCRM } })
    // Empleado
    const apiFilterEmpleado = new APIFilter()
    apiFilterEmpleado.addExact('idempleado', idempleado)
    apiCalls.push({ name: 'selectEmpleado', method: 'empleado.select', params: { filter: apiFilterEmpleado } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idoperacioncrm', id)
    const resp = await Vue.$api.call('operacioncrm.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
}